<template>
  <v-autocomplete
    v-model="provider"
    v-bind="$attrs"
    :items="$store.state.selects.providers"
    :loading="loading"
    :search-input.sync="search"
    :label="$tc('provider', 1) | capitalize"
    cache-items
    :dense="multiple"
    :multiple="multiple"
    clearable
    menu-props="offsetY"
    :item-text="multiple ? x => `${x.last_name}, ${x.first_name}` : 'last_name'"
    item-value="uuid"
    @change="$emit('update:value', provider)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        fal fa-user-doctor
      </v-icon>
    </template>
    <template #selection="{ item }">
      <v-chip
        v-if="multiple"
        x-small
        class="ml-0"
      >
        {{ item.first_name.charAt(0) }}. {{ item.last_name }}
      </v-chip>
      <template v-else>
        {{ item.last_name }}, {{ item.first_name }}
      </template>
    </template>
    <template
      v-if="!multiple"
      #item="{ item }"
    >
      {{ item.last_name }}, {{ item.first_name }}
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: [Array, Object, String],
        default: () => [],
      },
      params: {
        type: Object,
        default: () => ({}),
      },
      multiple: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        loading: false,
        search: null,
        provider: this.value,
      }
    },
    watch: {
      search () {
        if (this.value != this.search) {
          this.fetchProviders()
        }
      },
      value () {
        this.provider = this.value
      },
    },
    created () {
      if (this.value && !Array.isArray(this.value)) {
        this.search = this.value
        this.fetchProviders()
      } else {
        this.fetchProviders()
      }
    },
    methods: {
      fetchProviders () {
        this.loading = true
        const promise = this.axios.get('providers', {
          params: {
            ...this.params,
            ...{
              fields: ['uuid','first_name','last_name'],
              search: this.search,
            },
          },
        })
        return promise.then((response) => {
          this.$store.state.selects.providers = response.data.data
        }).catch(error => {
          this.$toast.error(error.response?.data?.message)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
